import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { Link } from '@dx-ui/osc-link';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { useGlobalConfig } from '../../hooks/use-global-config';

type TContactUsProps = {
  email?: string | null;
  networkDisclaimer?: string | null;
  phoneNumber: string | null;
};

type TArrivalTimeProps = {
  checkinInfo: NonNullable<GetHotelHomePageQuery['hotel']>['registration'];
};

export const ArrivalTime = ({ checkinInfo }: TArrivalTimeProps) => {
  const { t } = useTranslation('dx-utility-rail');
  const { isTailored } = useGlobalConfig();

  return (
    <div>
      <div
        className={cx('mb-0 text-sm font-bold capitalize md:mb-3', {
          'text-text-alt': !isTailored,
          'brand-ou:uppercase brand-ou:font-normal brand-ou:font-mono': isTailored,
        })}
      >
        {t('arrivalTime')}
      </div>
      <div className="flex flex-row items-start">
        <div className="whitespace-nowrap text-center">
          <span className="mb-1 text-center">{t('checkIn')}</span>
          <span
            data-testid="tab-check-in-time"
            className={cx('ml-1 text-center', {
              'font-bold text-text-alt': !isTailored,
            })}
          >
            {checkinInfo?.checkinTimeFmt}
          </span>
          <span className="mx-4 mb-1 text-center">&#8594;</span>
        </div>
        <div className="whitespace-nowrap text-center">
          <span className="text-center">{t('checkOut')}</span>
          <span
            data-testid="tab-check-out-time"
            className={cx('ml-1 text-center', {
              'font-bold text-text-alt': !isTailored,
              'font-normal': isTailored,
            })}
          >
            {checkinInfo?.checkoutTimeFmt}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ContactUs = ({ email, networkDisclaimer, phoneNumber }: TContactUsProps) => {
  const { t } = useTranslation('dx-utility-rail');
  const { isTailored } = useGlobalConfig();

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3 px-3 sm:flex-row sm:justify-between sm:px-0">
        <div className="sm:flex sm:flex-col sm:gap-3">
          <p
            aria-hidden
            className={cx('text-sm font-bold capitalize', {
              'text-text-alt': !isTailored,
              'brand-ou:uppercase brand-ou:font-normal brand-ou:font-mono': isTailored,
            })}
          >
            {t('call')}
          </p>
          <p className="sr-only">{t('call')}</p>
          <Link
            className={cx('whitespace-nowrap', {
              'hover:text-primary-alt': !isTailored,
              'brand-lx:font-normal brand-ou:font-normal': isTailored,
            })}
            role="link"
            adaDescription={`Phone: ${phoneNumber}`}
            label={phoneNumber || ''}
            url={`tel:${phoneNumber}`}
          >
            {phoneNumber}
          </Link>
        </div>
        {networkDisclaimer ? <p className="text-sm sm:hidden">{networkDisclaimer}</p> : null}
        {email ? (
          <div className="sm:flex sm:flex-col sm:gap-3">
            <p
              aria-hidden
              className={cx('text-sm font-bold capitalize', {
                'text-text-alt': !isTailored,
                'brand-ou:uppercase brand-ou:font-normal brand-ou:font-mono': isTailored,
              })}
            >
              {t('email')}
            </p>
            <p className="sr-only">{t('email')}</p>
            <a
              aria-label={email}
              className={cx('flex flex-row underline', {
                'flex-col xl:flex-row': (email.split('@')[0] || '').length >= 20,
                'hover:text-primary-alt': !isTailored,
              })}
              href={`mailto:${email}`}
            >
              <span>{email.split('@')[0]}</span>
              <span>{`@${email.split('@')[1]}`}</span>
            </a>
          </div>
        ) : null}
      </div>
      {networkDisclaimer ? (
        <p className="hidden text-sm sm:block sm:max-w-80" data-testid="networkDisclaimer">
          {networkDisclaimer}
        </p>
      ) : null}
    </div>
  );
};

export const FlexibleDivider = () => {
  const { isTailored } = useGlobalConfig();

  return (
    <div className="mx-4 flex justify-center font-normal" data-testid="utility-rail-divider">
      <div
        className={cx('border-r border-solid', {
          'border-border-alt': !isTailored,
          'border-border': isTailored,
        })}
      />
    </div>
  );
};
