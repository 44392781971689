import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { FC, RefObject } from 'react';
import { createRef, useState } from 'react';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { sendReward } from '@dx-ui/framework-conductrics';
import { getIntlNumberLocale } from '@dx-ui/framework-i18n';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { CONDUCTRICS_GOALS } from '../../helpers/constants';
import TripAdvisorReview from './tripAdvisorReviewBox';

type TripAdvisorType = {
  reviewData: NonNullable<GetHotelHomePageQuery['hotel']>['tripAdvisorLocationSummary'];
  shouldDisplayInRow?: boolean;
};

const TripAdvisor: FC<TripAdvisorType> = ({ reviewData, shouldDisplayInRow }) => {
  const { t } = useTranslation('trip-advisor');
  const language = useRouter().locale || 'en';
  const numReviews = reviewData?.numReviews ? reviewData?.numReviews : 0;
  const rating = new Intl.NumberFormat(getIntlNumberLocale(language), {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  }).format(reviewData?.rating || 0);
  const ratingImageUrl = reviewData?.ratingImageUrl ? reviewData?.ratingImageUrl : '';
  const reviewsData = reviewData?.reviews;
  const reviewsDataCount = reviewsData ? reviewsData?.length : 0;
  const modalRef: RefObject<HTMLButtonElement> = createRef();
  const [modalOpen, setModalOpen] = useState(false);

  if (!reviewData?.rating || reviewData.rating < 3.5) {
    return null;
  }

  const getModalContent = () => (
    <Dialog
      className="w-full"
      isOpen={modalOpen}
      onDismiss={() => setModalOpen(false)}
      title={t('reviews')}
      size="3xl"
    >
      <div className="pt-2 md:px-12 md:py-6">
        <div className="flex flex-col">
          <div className="mx-0 mb-4 mt-0 flex flex-col items-start md:mx-auto md:mb-10 md:mt-8 md:flex-row md:items-center">
            <picture>
              <img
                data-testid="dialog-rating-img"
                alt={t('imgAlt', { rating, count: numReviews })}
                src={ratingImageUrl}
                className="mb-4 w-48 md:mb-0 md:w-64"
              />
            </picture>
            <div className="flex flex-col px-2 pb-0 md:ml-5 md:p-0">
              <span className="mb-2 text-lg font-bold md:text-4xl">
                {t('ratingInfo', { rating })}
              </span>
              <span className="text-sm font-bold md:text-base">
                {t('basedOnReviews', {
                  count: numReviews,
                })}
              </span>
            </div>
          </div>
          <span className="border-border-alt my-3 self-stretch border border-solid" />
          <div>
            <div className="mb-10 flex flex-col items-center">
              <h3 data-testid="dialog-reviews-header" className="mb-1 mt-5 text-2xl font-bold">
                {t('reviewLengthTxt', {
                  count: reviewsDataCount,
                })}
              </h3>
              <div className="text-sm">{t('newReviewTxt')}</div>
            </div>
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
              {reviewsData?.map((reviewItem) => (
                <div key={reviewItem.id} data-testid={`trip-advisor-review-${reviewItem.id}`}>
                  <TripAdvisorReview review={reviewItem} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );

  if (shouldDisplayInRow) {
    return (
      <div className="flex flex-row items-center gap-4" data-testid="tripadvisor">
        <div className="flex flex-col items-start gap-1">
          <picture>
            <img
              className="relative -left-2 w-32"
              alt={t('imgAlt', { rating, count: numReviews })}
              src={ratingImageUrl}
            />
          </picture>
          <p className="text-sm font-bold">{t('rating', { rating })}</p>
        </div>
        <div className="flex flex-col items-start gap-1 text-sm">
          <div>
            <Trans i18nKey="basedOnGuestReviews" t={t} count={numReviews}>
              0 <span className="font-bold">1</span> 2
            </Trans>
          </div>
          <button
            className="cursor-pointer font-bold underline"
            onClick={() => {
              sendReward(CONDUCTRICS_GOALS.TRIPADVISOR_CTA_CLICK);
              setModalOpen(true);
            }}
            ref={modalRef}
            type="button"
          >
            {t('readReviews')}
          </button>
        </div>
        {getModalContent()}
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center gap-3 sm:items-start sm:justify-center"
      data-testid="tripadvisor"
    >
      <div>
        <picture>
          <img
            className="relative -left-2 w-48 sm:w-32"
            alt={t('imgAlt', { rating, count: numReviews })}
            src={ratingImageUrl}
          />
        </picture>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-4">
          <p className="font-bold">{rating}</p>
          <span className="border-border-alt self-stretch border border-solid" />
          <button
            className="cursor-pointer font-bold underline"
            onClick={() => {
              sendReward(CONDUCTRICS_GOALS.TRIPADVISOR_CTA_CLICK);
              setModalOpen(true);
            }}
            ref={modalRef}
            type="button"
          >
            {t('readReviews')}
          </button>
        </div>
        <div>
          <div className="text-text-alt text-sm">
            <Trans i18nKey="basedOnGuestReviews" t={t} count={numReviews}>
              0 <span className="font-bold">1</span> 2
            </Trans>
          </div>
        </div>
      </div>
      {getModalContent()}
    </div>
  );
};

export { TripAdvisor };
